<template>
  <div class="bindDevice">
    <el-row>
      <el-col :span="2"> </el-col>
      <el-button class="iconfont icon-dcicon_save1" type="primary" @click="save('form')">保存</el-button>
      <el-button class="iconfont icon-dcx" @click="quit()">取消</el-button>
    </el-row>
    <el-form ref="form" :model="form" label-width="100px">
      <baseSection name="绑定设备">
        <el-row type="flex" justify="space-between">
          <el-col :span="7">
            <el-form-item label="设备类型:" prop="deviceTypeId" :rules="[{ required: true, message: '请选择设备类型', trigger: 'blur' }]">
              <el-select v-model="form.deviceTypeId" placeholder="请选择...." style="width: 100%" @change="getTemplate()">
                <el-option v-for="item in typeOptions" :key="item.deviceTypeId" :label="item.name" :value="item.deviceTypeId"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="设备模板:" prop="templateId" :rules="[{ required: true, message: '请选择设备模板', trigger: 'blur' }]">
              <el-select v-model="form.templateId" placeholder="请选择...." @change="setIconurl()" style="width: 100%" :disabled="form.deviceTypeId === '' ? true : false">
                <el-option v-for="item in templateOptions" :key="item.templateId" :label="item.name" :value="item.templateId"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item
              label="采集频率:"
              prop="frequency"
              :rules="[
                { required: true, message: '请输入采集频率', trigger: 'blur' },
                { type: 'number', message: '采集频率必须为数字值' }
              ]"
            >
              <el-input v-model.number="form.frequency" placeholder="请输入数字(单位：ms)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </baseSection>
      <baseSection name="设备资料">
        <el-row>
          <el-col :offset="0" :span="8">
            <el-form-item label="设备图片">
              <img :src="iconUrl" alt="" style="width: 88px; height: 88px" />
            </el-form-item>
          </el-col>
          <el-col :offset="0" :span="8">
            <el-form-item label="状态显示">
              <el-switch v-model="form.isOpenProduce" active-color="#13ce66" inactive-color="#c8c5c8"> </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </baseSection>
    </el-form>
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
export default {
  components: {
    baseSection
  },
  props: ['id', 'iconUrl'],
  data() {
    return {
      form: {
        deviceId: this.id,
        frequency: '',
        deviceTypeId: '',
        templateId: '',
        isOpenProduce: false
      },
      typeOptions: [],
      templateOptions: []
    };
  },
  async mounted() {
    this.form.lineId = this.$route.query.data;
    const res = await this.$apis.device.deviceType();
    this.typeOptions = res;
  },
  methods: {
    quit() {
      this.$refs.form.resetFields();
      this.$router.go(-1);
    },
    setIconurl() {
      this.templateOptions.forEach(item => {
        if (item.templateId === this.form.templateId) {
          if (item.iconId !== 3) {
            this.form.iconUrl = item.iconUrl;
          } else {
            this.form.iconUrl = 'ecm/holdhope/2021-11-02/device.png';
          }
        }
      });
    },
    async getTemplate() {
      const deviceTypeId = this.form.deviceTypeId;
      const res = await this.$apis.device.deviceTemplate({ deviceTypeId });
      this.templateOptions = res;
    },
    async save(form) {
      if (this.form.frequency < 0) {
        this.$message.warning('采集频率不能为负数');
        return false;
      }
      if (this.form.frequency > 3600000) {
        this.$message.warning('采集频率不能大于一小时');
        return false;
      }
      this.$refs[form].validate(async valid => {
        if (valid) {
          const { fail } = await this.$apis.device.bindingDevice(this.form);
          // this.$emit('changeupdate');
          if (!fail) {
            this.$router.go(-1);
          }
        }
      });
    }
  }
};
</script>
<style scoped>
.el-row {
  margin-bottom: 20px;
}
</style>
